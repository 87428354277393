
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {ROUTES} from "@/router/routes";
  import {AuthActionTypes} from "@/store/modules/auth/actions";
  import {setLocale} from "@/services/i18n";

  export default Vue.extend({
    name: "pwa-sidebar-mobile",
    components: {
      PwaIcon,
    },
    data() {
      return {
        routes: ROUTES,
      }
    },
    computed: {
      getMainMinigameAlias(): string {
        let alias = '';

        const minigames = this.$store.state.user.minigames;
        if(minigames.length > 0){
          alias = minigames[0].alias;
        }

        return alias;
      },
      hasBlog(): boolean {
        return Boolean(process.env.VUE_APP_BLOG_ENDPOINT);
      },
      hasInviteFriend(): boolean {
        return Boolean(process.env.VUE_APP_HAS_INVITE_FRIEND);
      },
      hasLastPrizesDistributed(): boolean {
        return Boolean(process.env.VUE_APP_HAS_LAST_PRIZES_DISTRIBUTED);
      },
      hasPromotions(): boolean {
        return Boolean(process.env.VUE_APP_HAS_PROMOTIONS);
      },
      lang: {
        get: function () {
          return this.currentLang;
        },
        set: function (val: string) {
          setLocale(val, this.$router);
        }
      },
      xUrl(): string {
        return process.env.VUE_APP_X_URL;
      },
      instagramUrl(): string {
        return process.env.VUE_APP_INSTAGRAM_URL;
      },
      youtubeUrl(): string {
        return process.env.VUE_APP_YOUTUBE_URL;
      },
      whatsappUrl(): string {
        return process.env.VUE_APP_WHATSAPP_URL;
      },
    },
    methods: {
      logOut() {
        this.$store.dispatch(AuthActionTypes.LOGOUT);
      },
    },
  });
